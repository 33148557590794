/* styles.css */
.tooltip {
  border: 1px solid #4169e1; /* Blue border */
  background-color: transparent; /* Light blue tint with increased transparency */
  border-radius: 4px;
  padding: 10px;
  font-family: 'Lato', sans-serif; /* Updated font */
  font-size: 12px;
  font-weight: normal; /* Normal text */
  position: relative;
  max-width: 200px;
}

.tooltip-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.tooltip-dot {
  width: 8px;
  height: 8px;
  background-color: #4169e1; /* Example color */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.tooltip-title {
  font-family: 'Lato', sans-serif;
}

.tooltip-content {
  color: #333; /* Slightly darker for better readability */
  font-family: 'Lato', sans-serif;
}
