@import '../node_modules/@fluentui/react/dist/css/fabric.min.css';

@font-face {
  font-family: 'Microsoft Advertising MDL2 Assets';
  src: url('./MsAdsMDL2.ttf') format('truetype');
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: inherit;
  font-size: 16px;
}

.ms-Layer .ms-Panel {
  margin-top: 48px;
}

#root {
  height: inherit;
}
#xpay-cpc-onboarding-frame-id {
  width: 100%;
}

/* Apex import will create a container div to handle the CMUI help experience, it's handled while application
load and not visible in UI but creating extra vertical overflows, below styling as a wrokaround to address this issue */
body > #announce-suggestions {
  bottom: 0;
}

/* Customizes the default scrollbar of the Operating System */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #e1dfdd;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #d2d0ce;
}
